.App {
  text-align: center;
}

.App-name {
  font-size: calc(32px + 2vmin);
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  color: #ec598f;
  font-weight: 700;
}

.App-logo {
  height: 42px;
  width: 42px;
}

